body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.section {
  margin-bottom: 1.5rem;
  display: grid;
  align-items: center;
  width: 100%;
}

.input {
  font-family: "poppins";
  font-size: 18px;
  color: white;
  border-radius: 7px;
  padding: 0.7rem 1rem;
  background-color: transparent;
  border: none;
  width: 100%;
  box-sizing: border-box;
  outline: 1px solid white;
}

.input:focus {
  outline: 1px solid white;
}

.input[type="submit"] {
  cursor: pointer;
}
.black {
  color: black;
}
.label {
  background-color: transparent;
  justify-self: start;
  transition-property: transform, padding, font-size;
  transition-duration: 0.3s;
  margin: 0 1rem;
  pointer-events: none;
  color: white;
}

.label,
.input {
  grid-column-start: 1;
  grid-row-start: 1;
}

.input:focus ~ .label,
.input:not(:placeholder-shown) ~ .label {
  transform: translateY(-124%);
  font-size: 0.8rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.section ::placeholder {
  color: transparent;
}

.invalid .input {
  outline: 1px solid red;
}

.invalid .label {
  color: red;
}

.error {
  margin-top: 0.5rem;
  color: red;
}
.MuiDrawer-paper {
  background: rgba(255, 255, 255, 0.25) !important;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.MuiContainer-root {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
